<template lang="pug">
include ../../../configs/template
div
  form.row.mt-8
    div.col-12
      +select-validation('body.typeExperience', 'listTypesDocument', 'typeDoc', 'nameLang', '["required"]')
    ExperienceCertificate(
      v-if="TYPE_DOCUMENTS_CONSTANTS?.EXPERIENCE_CERTIFICATE === body.typeExperience"
      ref="SailorExperienceCertificate"
      :sailorExperienceCertificate="Certificate"
      :typeExperienceId="body.typeExperience").w-100
    EmploymentHistory(
      v-if="body.typeExperience === TYPE_DOCUMENTS_CONSTANTS?.EXPERIENCE_HISTORY"
      :sailorExperienceCertificate="Certificate"
      ref="EmploymentHistory").w-100
    div.col-12
      FileDropZone(ref="mediaContent")
    div.col-12
      v-btn(color="success" @click="validateData" :loading="isLoadingSave").mr-4 {{$t('save')}}
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { verificationSC } from '@/mixins/validationRules'
import { TYPE_DOCUMENTS_CONSTANTS, SUCCESS_CODE } from '@/configs/constants'
import { clearBody } from '@/mixins/main'

export default {
  props: {
    Certificate: { type: Object, default: () => ({}) }
  },
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue'),
    EmploymentHistory: () => import('./Forms/EmploymentHistory.vue'),
    ExperienceCertificate: () => import('./Forms/ExperienceCertificate.vue')
  },
  data () {
    return {
      TYPE_DOCUMENTS_CONSTANTS,
      body: {
        typeExperience: 14
      },
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      documentTypes: state => state.directory.verificationDocumentTypes,
      listConventionalTypeDoc: state => state.directory.listConventionalTypeDoc,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      isLoadingSave: state => state.sailor.isLoading
    }),
    listTypesDocument () {
      return this.documentTypes.concat(this.listConventionalTypeDoc).filter(el => (
        [TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_CERTIFICATE,
          TYPE_DOCUMENTS_CONSTANTS?.EXPERIENCE_HISTORY].includes(el.id)))
    }
  },
  validations () { return verificationSC.experience() },
  watch: {
    'body.typeExperience' (newVal, oldVal) {
      if (newVal && newVal?.id !== oldVal?.id) {
        this.clearForm()
      }
    }
  },
  mounted () {
    if (Object.keys(this.Certificate).length) {
      this.body.typeExperience = this.listTypesDocument.find(el => el.name_ukr === this.Certificate.record_type).id
    }
  },
  methods: {
    ...mapActions(['setExperienceCertificate', 'updateExperienceCertificateById']),
    async validateData () {
      let verificationData = {
        ...this.$route.params
      }
      if (this.$v.$invalid) return this.$v.$touch()
      switch (this.body.typeExperience) {
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_CERTIFICATE:
          if (this.$refs.SailorExperienceCertificate.$v.$invalid) {
            return this.$refs.SailorExperienceCertificate.$v.$touch()
          }

          this.$refs.SailorExperienceCertificate.body.additional_info.full_name_master = this.$refs.SailorExperienceCertificate.body.additional_info.full_name_master_ukr
          verificationData.body = clearBody({ ...this.$refs.SailorExperienceCertificate.body.additional_info })
          verificationData.media = {
            name: 'ExperienceDoc',
            files: [...this.$refs.mediaContent.filesArray]
          }
          verificationData.body.record_type = 'Довідка про стаж плавання'
          break
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_HISTORY:
          if (this.$refs.EmploymentHistory.$v.$invalid) {
            return this.$refs.EmploymentHistory.$v.$touch()
          }
          verificationData.body = { ...clearBody({ ...this.$refs.EmploymentHistory.body.additional_info }) }
          verificationData.body.record_type = this.listTypesDocument.find(el => this.body.typeExperience === el.id).name_ukr
          verificationData.media = {
            name: 'ExperienceDoc',
            files: [...this.$refs.mediaContent.filesArray]
          }
          break
      }
      let response
      if (Object.keys(this.Certificate).length) response = await this.updateExperienceCertificateById(verificationData)
      else response = await this.setExperienceCertificate(verificationData)
      if (SUCCESS_CODE.includes(response.code)) {
        this.$route.params.documentID && this.$notification.success('infoExperienceDoc')
        this.clearForm()
      }
    },
    clearForm () {
      if (this.$refs.SailorExperienceCertificate) this.$refs.SailorExperienceCertificate.clearBody()
      else if (this.$refs.EmploymentHistory) this.$refs.EmploymentHistory.clearBody()
      this.$v.$reset()
      if (!Object.keys(this.Certificate).length) this.$parent.isViewAddSlot = !this.$parent.isViewAddSlot
    }
  }
}
</script>
